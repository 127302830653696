import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faWhatsapp, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const icons = [faFacebook, faWhatsapp, faInstagram, faLinkedin, faYoutube, faEnvelope]
icons.forEach( icon => library.add(icon))

export default (app) => {
  app.component('faIcon', FontAwesomeIcon)
}
