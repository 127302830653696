<template lang='pug'>
.o-exportLeads
  .o-exportLeads__datePicker
    el-date-picker(
      v-model='dates'
      type='daterange'
      placeholder='Selecciona un dia'
      :disabled-date='disabledDate'
    )

  .o-exportLeads__btnFilter
    el-button(
      type='primary'
      @click='export'
      :disabled='disabledExport'
    ) Exportar

  .o-exportLeads__btnDownload(
    v-if='linkExport.length'
  )
    a(
      :href='linkExport'
      target='_blank'
    )
      el-button(
        type='success'
      ) Descargar CSV
</template>

<script>
export default {
  name: 'OExportLeads',

  data () {
    return {
      dates: null,
      linkExport: '',
      loadingExport: false
    }
  },

  computed: {
    datesAllow () {
      const { dates } = this
      const daysAvailable = 31
      let totalDays

      if (dates) {
        const start_date = dates[0]
        const end_date = dates[1]
        const difference = end_date.getTime() - start_date.getTime()
        totalDays = difference/(1000 * 3600 * 24)
      }

      return dates != null && totalDays <= (daysAvailable - 1)
    },

    disabledExport () {
      return !this.datesAllow || this.loadingExport
    }
  },

  methods: {
    disabledDate(time) {
      return time.getTime() > Date.now()
    },

    async export () {
      try {
        const { dates } = this
        const payload = {
          app: 'Eventos',
          start_date: dates[0].setHours(0, 0, 0, 0),
          end_date: dates[1].setHours(23, 59, 59, 999)
        }
        this.loadingExport = true
        
        const { data } = await this.axios.post('/export_clients', payload)

        this.linkExport = data.Location
        this.loadingExport = false

        this.$message.success('Se exportó correctamente.')
      } catch (e) {
        this.$message.error(`Hubo un error al exportar. ${e}`)
      }
    }
  }
}
</script>

<style lang='sass' scoped>
@import 'export_leads.sass'
</style>
