<template lang='pug'>
.o-uploadImage__uploader(
  v-loading='loading'
)
  el-upload(
    ref='upload'
    action=''
    :http-request='saveFile'
    :show-file-list='false'
    :before-upload='beforeUploadFile'
  )
    i.o-uploadImage__uploaderIcon(
      v-if='loading'
      class="el-icon-plus"
    )
    img.o-uploadImage__uploaderImage(
      v-else
      :src='url'
    )
</template>

<script>
import AWS from 'aws-sdk'

export default {
  name: 'OManagement',
  props: {
    imageName: {
      required: true,
      type: String
    }
  },
  data () {
    return {
      url: 'https://landings-edifica.s3.amazonaws.com/eventos/' + this.imageName,
      loading: false
    }
  },
  methods: {
    beforeUploadFile (file) {
      const typesValid = ['image/jpeg', 'image/jpg', 'image/png']
      const isTypeValid = typesValid.includes(file.type)

      const limitSize = 4 // MB
      const limitMB = file.size < (1000000 * limitSize)

      if (!isTypeValid) {
        this.$message.error('La imagen debe estar en formato JPEG, JPG o PNG!')
      }

      if (!limitMB) {
        this.$message.error(`La imagen excede los ${limitSize}MB!`)
      }

      return isTypeValid && limitMB
    },

    async saveFile (res) {
      try {
        this.loading = true

        const s3 = new AWS.S3()

        const file = res.file
        const params = {
          Bucket: 'landings-edifica',
          Key: 'eventos/' + this.imageName,
          Body: file,
          ContentType: file.type,
          ACL: 'public-read'
        }

        await s3.upload(params).promise()
        
        this.loading = false
        this.$refs.upload.clearFiles()

        this.$message({
          message: 'La imagen se guardó correctamente.',
          type: 'success'
        })
      } catch (e) {
        this.$message({
          message: `Hubo un error al guardar. ${e}`,
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped lang='sass'>
@import './upload_image.sass'
</style>
