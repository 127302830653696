import { Liquid } from 'liquidjs'

const liquidize = async (string, params) => {
  try {
    const engine = new Liquid()
    const data = engine.parse(string)
    const mail = await engine.render(data, params)

    return mail
  } catch (e) {
    console.log(e)
  }
}

export default liquidize
