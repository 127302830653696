<template lang='pug'>
.t-home
  o-navbar

  .t-home__wrapper

    .t-home__InfoTitleDesktop
      h1.text-primary__color {{ data.title1 }} 
        span.text-secondary__color {{ data.title2 }}

    .t-home__event
      .t-home__eventInfo
        .t-home__eventInfoImage
          img(
            :src='imageSrc'
          )

        .t-home__InfoTitleMobile
          h3.text-primary__color {{ data.title1 }} 
            span.text-secondary__color {{ data.title2 }}

      .t-home__eventContact
        .t-home__eventContactForm
          p Déjanos tus datos y 
            strong confirma tu asistencia
          o-contact(
            :data='data'
          )

  o-footer
</template>

<script>
import ONavbar from '@/components/organisms/navbar'
import OContact from '@/components/organisms/contact'
import OFooter from '@/components/organisms/footer'

export default {
  name: 'THome',
  components: {
    OContact,
    ONavbar,
    OFooter
  },
  props: {
    data: Object
  },
  data () {
    return {
      imageSrc: 'https://landings-edifica.s3.amazonaws.com/eventos/event'
    }
  }
}
</script>

<style scoped lang='sass'>
@import './home.sass'
</style>
