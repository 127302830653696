<template lang='pug'>
.o-footer
  .o-footer__info

    .o-footer__infoLogo.o-footer__infoSide
      img.o-footer__infoLogoImg(src='@/assets/images/footer-logo.svg')

    .o-footer__infoSocials.o-footer__infoSide
      .o-footer__infoSocialsField(
        v-for='social in socials'
        :class='classFromSocial(social)'
      )
        a(
          :href='social.url'
          target='_blank'
        )
          fa-icon(
            class='o-footer__infoSocialsFieldItem'
            :icon='getIcon(social)'
          )
          span.o-footer__infoSocialsFieldName(v-if='social.name') {{ social.name }}

  .o-footer__copyright
    p 2021 Edifica Inmobiliaria. Todos los derechos reservados.
</template>

<script>
export default {
  name: 'OFooter',
  data () {
    return {
      socials: [
        {
          icon: 'whatsapp',
          url: 'https://api.whatsapp.com/send?phone=51989344159&text=Buenas,%20le%20escribo%20para%20obtener%20informaci%C3%B3n%20del%20evento',
          name: '+51 989 344 159'
        },
        {
          type: 'fas',
          icon: 'envelope',
          url: 'mailto:info@edifica.com.pe',
          name: 'info@edifica.com.pe'
        },
        {
          icon: 'facebook',
          url: 'https://www.facebook.com/inmobiliaria.edifica'
        },
        {
          icon: 'instagram',
          url: 'https://www.instagram.com/edificainmobiliaria/'
        },
        {
          icon: 'linkedin',
          url: 'https://www.linkedin.com/company/grupo-edifica/'
        },
        {
          icon: 'youtube',
          url: 'https://www.youtube.com/channel/UCit7ndXx81UQr_17zOFpOag'
        }
      ]
    }
  },
  methods: {
    getIcon (data) {
      const type = data.type || 'fab'
      return [type, data.icon]
    },
    classFromSocial (social) {
      const name = social.icon.charAt(0).toUpperCase() + social.icon.slice(1)
      return `o-footer__infoSocialsField${name}`
    }
  }
}
</script>

<style lang='sass'>
@import './footer.sass'
</style>
