<template lang='pug'>
.o-navbar
  img(src='@/assets/images/logo.svg').o-navbar__logo
</template>

<script>
export default {
  name: 'ONavbar'
}
</script>

<style scoped lang='sass'>
@import './navbar.sass'
</style>
