import AWS from 'aws-sdk'

export default () => {
  AWS.config.region = 'us-east-1'
  AWS.config.apiVersions = {
    s3: '2012-10-17',
    dynamodb: '2012-08-10',
    ses: '2010-12-01'
  }
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTITY
  })
}
