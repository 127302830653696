import readHtml from './readHtml.js'
import liquidize from './liquidize.js'
import sendEmailSES from './sendEmailSES.js'

const mixins = {
  methods: {
    readHtml,
    liquidize,
    sendEmailSES
  }
}

export default mixins
