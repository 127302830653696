<template lang='pug'>
el-form.o-login(
  ref='form'
  :model='login'
)
  el-form-item(label='Usuario')
    el-input(
      v-model='login.username'
      placeholder='Ingrese su usuario'
    )

  el-form-item(label='Contraseña')
    el-input(
      v-model='login.password'
      type='password'
      placeholder='Ingrese su contraseña'
    )

  el-form-item
    el-button(
      type='primary'
      native-type='submit'
      @click.prevent='onSubmit'
    ) Iniciar Sesion
</template>

<script>
export default {
  name: 'OLogin',
  data () {
    return {
      login: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    onSubmit () {
      if (this.login.username == process.env.VUE_APP_USER && this.login.password == process.env.VUE_APP_PASS) {
        this.$emit('login')

        this.$message({
          message: 'Iniciaste sesión correctamente.',
          type: 'success'
        })
      } else {
        this.$message({
          message: 'El usuario o contraseña son incorrectos.',
          type: 'error'
        })
      }
    }
  }
}
</script>

<style scoped lang='sass'>
@import './login.sass'
</style>
