import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import mixins from './mixins'

import initAws from './plugins/aws'
import initAxios from './plugins/axios'
import initElementPlus from './plugins/element'
import initFontAwesome from './plugins/font-awesome'

const app = createApp(App)

app.mixin(mixins)
initAws()
initAxios(app)
initElementPlus(app)
initFontAwesome(app)
app.use(router).mount('#app')
