<template lang='pug'>
el-form(
    ref='form'
    :model='form'
    :rules='rules'
    v-loading='formLoad'
  ).o-contact

  el-form-item(
    label='Nombre'
    prop='fname'
  )
    el-input(v-model='form.fname')

  .o-contact__group
    el-form-item.o-contact__groupField(
      label='DNI'
      prop='document'
    )
      el-input(v-model='form.document')

    el-form-item.o-contact__groupField(
      label='Celular'
      prop='phone'
    )
      el-input(v-model='form.phone')

  el-form-item(
    label='Email'
    prop='email'
  )
    el-input(v-model='form.email')

  el-form-item(
    label='Proyecto de Interés'
    prop='project_id'
  )
    el-select.o-contact__fieldProjectRelated(
      v-loading='projectsLoad'
      v-model='form.project_id'
      placeholder='Proyecto'
    )
      el-option(
        v-for='project in data.projects'
        :label='project.name'
        :value='project.id'
      )

  el-form-item(
    label='Comentario'
    prop='observation'
  )
    el-input(
      type='textarea'
      v-model='form.observation'
      :autosize="{ minRows: 4, maxRows: 5}"
    )

  .o-contact__submit
    el-form-item
      el-button(
        @click='onClickSubmit'
        type='primary'
      ) Enviar

</template>

<script>
export default {
  name: 'OContact',
  props: {
    data: Object
  },
  data () {
    return {
      formLoad: false,
      form: {
        fname: '',
        phone: '',
        email: '',
        document: '',
        project_id: null,
        observation: '',
        input_channel_id: 28,
        source_id: 81,
        interest_type_id: 4,
        utm_source: 'Evento Web',
        utm_medium: 'Web',
        utm_campaign: this.data.utm_campaign || 'Evento Web',
        app: 'Eventos'
      },
      rules: {
        fname: [
          { required: true, message: 'Ingresa tu nombre.', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Ingresa tu número de celular.', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Ingresa tu email.', trigger: 'blur' },
          { type: 'email', message: 'Ingresa un email válido', trigger: 'blur' }
        ],
        document: [
          { required: true, message: 'Ingresa tu DNI.', trigger: 'blur' }
        ],
        project_id: [
          { required: true, message: 'Selecciona un Proyecto de Interés.', trigger: 'change' }
        ],
        observation: [
          { required: true, message: 'Ingresa un comentario.', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    async sendMail () {
      try {
        const payload = {
          client: this.form,
          data: this.data
        }

        const emailString = await this.readHtml('mailer.html')
        const mail = await this.liquidize(emailString, payload)
        await this.sendEmailSES('info@edifica.com.pe', this.form.email, 'Registro a Evento', mail)

        this.$message.success('Se le envió un correo.')
      } catch (e) {
        console.log(e)
        this.$message.error(`Hubo un error al enviar el correo. ${e}`)
      }
    },
    onClickSubmit () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.submitForm()
        } else {
          return false
        }
      })
    },
    async submitForm () {
      try {
        this.formLoad = true

        await this.axios.post('/clients/register', this.form)
        await this.sendMail()
        eval(this.data.fb_track)

        this.formLoad = false

        this.$refs.form.resetFields()
        this.$router.push('/thank_you')
        this.$message.success('Ya tenemos tus datos, pronto nos pondremos en contacto contigo.')
      } catch (e) {

        this.formLoad = false
        this.$message.error('Upps, no pudimos guardar tus datos, te contactaremos.')
      }
    }
  }
}
</script>

<style scoped lang='sass'>
@import './contact.sass'
</style>
