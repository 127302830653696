<template lang='pug'>
t-home(
  :data='data'
)
</template>

<script>
import THome from '@/components/templates/home'
import AWS from 'aws-sdk'

export default {
  name: 'Home',
  components: {
    THome
  },
  data () {
    return {
      data: {
        title1: '',
        title2: '',
        utm_campaign: '',
        projects: [],
        fb_track: ''
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const params = {
          TableName: 'landingEventos',
          Key: {
            'Id': 1
          }
        }

        const documentClient = new AWS.DynamoDB.DocumentClient()
        const { Item } = await documentClient.get(params).promise()

        this.data = Item
        document.title = `${Item.title1} ${Item.title2}`
      } catch (e) {
        this.$message.error('Hubo un error al obtener la información')
      }
    }
  }
}
</script>
