<template lang='pug'>
div
  t-admin(
    v-if='userIsLoggedIn'
    @logout='logout'
  )
  t-login(
    v-else
    @login='login'
  )
</template>

<script>
import TLogin from '@/components/templates/login'
import TAdmin from '@/components/templates/admin'

export default {
  name: 'Admin',
  components: {
    TLogin,
    TAdmin
  },
  data () {
    return {
      userIsLoggedIn: false
    }
  },
  methods: {
    login () {
      this.userIsLoggedIn = true
    },
    logout () {
      this.userIsLoggedIn = false
    }
  }
}
</script>
