<template lang='pug'>
t-thank-you
</template>

<script>
import TThankYou from '@/components/templates/thank_you'

export default {
  name: 'ThankYou',

  components: {
    TThankYou
  }
}
</script>
