<template lang='pug'>
el-container.t-admin
  el-header.t-admin__header
    el-button.t-admin__logout(
      type='primary'
      @click='logout'
    ) Cerrar Sesión

  el-main.t-admin__main
    el-row
      el-col.t-admin__section(
        :span='12'
        :xs='24'
      )
        .t-admin__sectionTitle
          h1 Exportable
          el-tooltip(content='Rango máximo de 31 días' placement='right')
            i.el-icon-info

        OExportLeads

        h1 Información
        OManagement

      el-col.t-admin__section(
        :span='12'
        :xs='24'
      )
        .t-admin__sectionTitle
          h1 Imagen
          el-tooltip(content='920x1280' placement='right')
            i.el-icon-info

        OUploadImage(
          imageName='event'
        )

      el-col.t-admin__section.t-admin__sectionMail(
        :span='24'
        :xs='24'
      )
        .t-admin__sectionMailImage
          .t-admin__sectionTitle
            h1 Mail Header
            el-tooltip(content='800x611' placement='right')
              i.el-icon-info

          OUploadImage(
            imageName='mail_header'
          )

        .t-admin__sectionMailImage
          .t-admin__sectionTitle
            h1 Mail Body
            el-tooltip(content='800x1000' placement='right')
              i.el-icon-info

          OUploadImage(
            imageName='mail_body'
          )
</template>

<script>
import OManagement from '@/components/organisms/management'
import OUploadImage from '@/components/organisms/upload_image'
import OExportLeads from '@/components/organisms/export_leads'

export default {
  name: 'TAdmin',
  components: {
    OManagement,
    OUploadImage,
    OExportLeads
  },
  methods: {
    logout () {
      this.$emit('logout')
    }
  }
}
</script>

<style scoped lang='sass'>
@import './admin.sass'
</style>
