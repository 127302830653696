<template lang='pug'>
el-container.t-login
  el-main.t-login__main
    o-login(
      @login='login'
    )
</template>

<script>
import OLogin from '@/components/organisms/login'

export default {
  name: 'TLogin',
  components: {
    OLogin
  },
  methods: {
    login () {
      this.$emit('login')
    }
  }
}
</script>

<style scoped lang='sass'>
@import './login.sass'
</style>
