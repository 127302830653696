<template lang='pug'>
.t-thankYou
  o-navbar

  .t-thankYou__wrapper
    .t-thankYou__content
      img.t-thankYou__contentEmail(
        :src='icoEmail'
      )

      h1 ¡Gracias por registrarte!
      h4 ¡Tu mensaje fue enviado!

      el-button.t-thankYou__backHome(
        @click='backHome'
        type='primary'
      ) Ir a Inicio

  o-footer
</template>

<script>
import ONavbar from '@/components/organisms/navbar'
import OFooter from '@/components/organisms/footer'
import icoEmail from '@/assets/images/ico-email.svg'

export default {
  name: 'TThankYou',

  components: {
    ONavbar,
    OFooter
  },

  data () {
    return {
      icoEmail: icoEmail
    }
  },

  methods: {
    backHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang='sass' scoped>
@import './thank_you.sass'
</style>
