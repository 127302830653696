import axios from 'axios'

const readHtml = async (file) => {
  try {
    var { data } = await axios({
      method: 'GET',
      url: file,
      baseURL: '/'
    })

    return data
  } catch (e) {
    console.log(e)
  }
}

export default readHtml
