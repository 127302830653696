import AWS from 'aws-sdk'

const sendEmailSES = async (from, to, subject, body) => {
  const ses = new AWS.SES()

  var params = {
    Destination: {
      ToAddresses: [to]
    },
    Message: {
      Body: {
        Html: {
          Charset: 'UTF-8',
          Data: body
        },
      },
      Subject: {
        Charset: 'UTF-8',
        Data: subject
      }
    },
    Source: from
  }

  await ses.sendEmail(params).promise()
}

export default sendEmailSES
