<template lang='pug'>
el-form.o-management(
  ref='form'
  v-loading='infoLoad'
  :model='form'
)
  .o-management__title
    .o-management__group
      el-form-item.o-management__groupField(label='Titulo negro')
        el-input(
          v-model='form.title1'
        )

      el-form-item.o-management__groupField(label='Titulo color')
        el-input(
          v-model='form.title2'
        )

    .o-management__titleText
      h3.text-primary__color {{ form.title1 }} 
        span.text-secondary__color {{ form.title2 }}

  el-form-item(label='Utm Campaign')
    el-input(
      v-model='form.utm_campaign'
    )

  el-form-item(label='Mail Link')
    el-input(
      v-model='form.mail_link'
    )

  el-form-item(
    label='Proyectos'
  )
    el-select.o-management__projectIds(
      v-loading='projectsLoad'
      v-model='form.project_ids'
      placeholder='Proyecto'
      multiple
    )
      el-option(
        v-for='project in projects'
        :label='project.name'
        :value='project.id'
      )

  el-form-item(
    label='FB Track'
  )
    el-input(
      v-model='form.fb_track'
    )

  el-form-item
    el-button(
      type='primary'
      @click='onSave'
    ) Guardar
</template>

<script>
import AWS from 'aws-sdk'

export default {
  name: 'OManagement',
  data () {
    return {
      projects: [],
      projectsLoad: false,
      infoLoad: false,
      form: {
        title1: '',
        title2: '',
        utm_campaign: '',
        mail_link: '',
        project_ids: [],
        fb_track: ''
      }
    }
  },
  mounted () {
    this.getProjects()
    this.getInfo()
  },
  methods: {
    async getInfo () {
      try {
        this.infoLoad = true
        const params = {
          TableName: 'landingEventos',
          Key: {
            'Id': 1
          }
        }

        const documentClient = new AWS.DynamoDB.DocumentClient()
        const { Item } = await documentClient.get(params).promise()

        this.form.project_ids = Item.projects.map(p => p.id)
        this.form.title1 = Item.title1
        this.form.title2 = Item.title2
        this.form.utm_campaign = Item.utm_campaign
        this.form.mail_link = Item.mail_link
        this.form.fb_track = Item.fb_track

        this.infoLoad = false
        this.$message.success('Se cargó la información correctamente.')
      } catch (e) {
        this.$message.error('Hubo un error al obtener la información')
      }
    },
    async getProjects () {
      try {
        this.projectsLoad = true

        const { data } = await this.axios.get('/projects/list')

        this.projects = data.map( project => project.attributes )
        this.projectsLoad = false
      } catch (e) {
        this.$message.error(`Hubo un error al obtener los proyectos. ${e}`)
      }
    },
    async onSave () {
      try {
        const self = this
        this.infoLoad = true

        const documentClient = new AWS.DynamoDB.DocumentClient()
        const projects = this.projects.filter(p => self.form.project_ids.includes(p.id)).map( ({id, name}) => ({id, name}) )
        const params = {
          TableName: 'landingEventos',
          Key: {
            'Id': 1
          },
          UpdateExpression: 'set title1 = :title1, title2 = :title2, projects = :projects, utm_campaign = :utm_campaign, mail_link = :mail_link, fb_track = :fb_track',
          ExpressionAttributeValues: {
            ':title1': this.form.title1,
            ':title2': this.form.title2,
            ':projects': projects,
            ':utm_campaign': this.form.utm_campaign,
            ':mail_link': this.form.mail_link,
            ':fb_track': this.form.fb_track
          }
        }

        await documentClient.update(params).promise()
        
        this.infoLoad = false
        this.$message.success('Se guardó correctamente.')
      } catch (e) {
        this.$message.error(`Hubo un error al guardar. ${e}`)
      }
    },
  }
}
</script>

<style scoped lang='sass'>
@import './management.sass'
</style>
